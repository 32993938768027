
/* global index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }
  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  max-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageTitle {
  @apply text-2xl text-primaryColor font-bold;
}

.removeScroll::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.removeScroll::-webkit-scrollbar-thumb {
  /* background: #ff0000; */
}

/* remove leaflet default tooltip styles */

.leaflet-tooltip,
.leaflet-zoom-animated,
.leaflet-tooltip-center {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 40px !important;
  opacity: 1 !important;
  box-shadow: none !important;
}

/* Removes Google logo from the map */
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
/* relocate leaflet zoom control button */
.leaflet-control-container {
  position: absolute;
  right: 55px;
  top: 0px;
}
.leaflet-control-attribution {
  display: none;
}

.main-wrapper {
  padding: 16px 24px;
}
.section-wrapper {
  padding: 16px
}

.error {
  color: red;
  font-size: 12px;
}

.loading .progress_bar {
  display: block !important;
}

.progress_bar {
  display: none !important;
}

.loading .loading-item {
  display: table-row !important;
}

.loading-item {
  display: none !important;
}

.loading .no-data-found {
  display: none !important;
}

.no-data-found {
  display: flex !important;
}

.disabledContent {
  pointer-events: none;
  opacity: 0.4;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: rgba(0, 0, 0, 0.4);
  }
.leaflet-tooltip-own {
  font-size: 16px;
  position: absolute;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  border: 0px solid #000;
  color: #000;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

.css-1s2u09g-control {
  border-color: rgb(231 231 231) !important;
  border-radius: 8px !important;
  height: 42px !important;
/* @apply rounded-lg border-gray-500 */
}
.css-26l3qy-menu {
  z-index: 99999 !important;
}

.marker-icon {
  margin-left: -16px !important; /* -x */
  margin-top: -36px !important; /* -y */
}

 .pin1 {
  border-radius: 50%;
  border: 2px solid rgba(247, 235, 218, 0.8);
  width: 26px;
  height: 26px;
  /* transform: rotate(-45deg); */
  font-size: 16px;
  position: relative;
}

 .pin1 span {
  width:100%;
  left: 0px;
  top:-1px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* transform: rotate(45deg); */
  position: absolute;
  z-index:100000;
}
 .pin1::after {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -9px;
  background-color: rgb(255, 255, 255);
}

.selectedPin::after {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -9px;
  background-color: red;
}

