.main {
	display: flex;
	height: 100vh;
}

.page_view {
	flex-grow: 1;
	height: 100%;
	overflow: scroll;
}

.sidebar_hidden {
	display: none;
}

.sidebar {
	background-color: #000000;
	color: #ffffff;
	width: 56px;
	padding: 16px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 20px;
	transition: width 0.6s;
	height: 100%;
	overflow: scroll;
	white-space: nowrap;

	.nav-list svg path {
		fill: #ffffff;
	}

	& * {
		transition: 0.6s, color 0.01s;
	}

	& &__logo {
		path {
			fill: #fbb106;
		}
	}

	& &__search-icon {
		margin-top: 20px;
		fill: #424242 !important;
	}

	&__nav-link--active {
		color: #ff9800;

		svg path {
			fill: #ff9800;
		}
	}

	&__header {
		display: none;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 16px;

		&-logo {
			width: 124px;
		}
	}

	&__search {
		width: 0;
		height: 0;
		overflow: hidden;
		transition: width 0.2s;
		padding: 0 16px;
	}

	.sidebar__nav {
		width: 100%;
		margin-top: 20px;
	}

	.sidebar__nav__sub-item__button {
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: capitalize;
		font-weight: 500;
		padding: 9px 0;

		p {
			display: none;
			width: 80%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.chevron-icon {
		display: none;
	}

	.sidebar__nav__sub-item__icon {
		padding: 6px;
		border-radius: 4px;
		background-color: #ffffff22;
		margin-right: 10px;
		width: 33px;
		height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.active {
		color: #ff9800;
	}

	.sidebar__nav__sub-s_list {
		display: none;
	}

	.sidebar__nav__sub-s_item a {
		display: block;
		margin: 5px 0px;
	}

	&__nav-list {
		list-style: none;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.sidebar__nav-menu {
		width: 100%;
	}

	.sidebar__nav-header {
		text-transform: capitalize;
		font-size: 0px;
		color: #424242;
		padding: 0.5px;
		background-color: #424242;
	}

	&:hover {
		width: 230px;
		align-items: flex-start;
	}

	&:hover .sidebar__nav__sub-s_list {
		display: block;
		padding-left: 42px;
		text-transform: capitalize;
	}

	&:hover &__logo {
		display: none;
	}

	&:hover .sidebar__nav__sub-item__button p {
		display: block;
	}

	&:hover &__header {
		display: flex;
	}

	&:hover .sidebar__nav-menu {
		padding: 0 16px;
	}

	&:hover .chevron-icon {
		display: block;
	}

	&:hover .sidebar__nav-header {
		display: block;
		font-size: 14px;
		height: auto;
		background-color: transparent;
	}

	&:hover &__search {
		display: block;
		width: 100%;
		height: auto;
		margin-top: 20px;
		position: relative;

		&-input {
			padding: 8px 12px;
			width: 100%;
			font-size: 14px;
			outline: none;
			background-color: #ffffff11;
		}

		.sidebar__search-icon {
			display: block;
			position: absolute;
			top: 0;
			transform: translateY(-50%);
			right: 26px;
			border-radius: 4px;
		}
	}

	&:hover &__search-icon {
		display: none;
	}
}
