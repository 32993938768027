.stepper-list {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.line {
		flex-grow: 1;
		border-top: 1.5px dotted gray;

		&.completed,
		&.in_progress {
			border-color: #4caf50;
			border-style: solid;
		}
	}

	.stepper-item {
		display: flex;
		position: relative;

		&.completed {
			.stepper-image-wrapper {
				background-color: #4caf50;
			}
		}

		&.in_progress {
			background-color: #ff9800;
			border-radius: 50px;
			padding: 5px;

			.stepper-image-wrapper {
				background-color: white;
			}

			.stepper-content {
				display: block;
			}
		}

		.stepper-image-wrapper {
			width: 45px;
			height: 45px;
			align-items: center;
			justify-content: center;
			border-radius: 50px;
			display: flex;
			background-color: #eeeeee;
		}
	}

	.stepper-image-wrapper {
		img {
			width: 25px;
			height: 25px;
		}
	}

	.stepper-content {
		display: none;
		padding: 3px 10px 3px 7px;
		color: white;

		.stepper-title {
			font-size: 14px;
			font-weight: bold;
		}

		.stepper-text {
			font-size: 9px;
		}
	}
}

@media (max-width: 450px) {
	.stepper-list {
		.stepper-item {
			.stepper-image-wrapper {
				width: 30px;
				height: 30px;
				border-radius: 50px;

				img {
					width: 20px;
					height: 20px;
				}
			}

			.stepper-content {
				.stepper-title {
					font-size: 12px;
				}

				.stepper-text {
					font-size: 7px;
				}
			}
		}
	}
}

.vertical-stepper {
	.cancelled,
	.exception,
	.misrouted,
	.vertical-stepper-item {
		.vertical-stepper-item-styles {
			.vertical-stepper-items-circle {
				background-color: #0000001f;
			}
		}

		.vertical-stepper-content {
			p {
				background-color: #00000061;
			}
		}
	}

	.new_order,
	.processing,
	.ready_for_delivery,
	.picked_up,
	.in_transit,
	.processing_at_warehouse,
	.in_warehouse,
	.intransit,
	.in_transit_to_warehouse {
		.vertical-stepper-item-styles {
			.vertical-stepper-items-circle {
				background-color: #ff9800;
			}
		}

		.vertical-stepper-content {
			p {
				background-color: #ff9800;
			}
		}
	}

	.completed {
		.vertical-stepper-item-styles {
			.vertical-stepper-items-circle {
				background-color: #4caf50;
			}
		}

		.vertical-stepper-content {
			p {
				background-color: #4caf50;
			}
		}
	}

	.returned {
		.vertical-stepper-item-styles {
			.vertical-stepper-items-circle {
				background-color: #a020f0;
			}
		}

		.vertical-stepper-content {
			p {
				background-color: #a020f0;
			}
		}
	}

	.vertical-stepper-item {
		display: flex;

		// 		new_order
		// processing
		// ready_for_delivery
		// picked_up
		// intransit
		// completed
		// cancelled
		// exception
		// returned
		// damaged_or_lost
		// not_ready_for_pickup
		// pickup_failed
		// processing_at_warehouse
		// in_warehouse
		// misrouted
		// in_transit_to_warehouse
		// #FF9800

		.vertical-stepper-item-styles {
			width: 10%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.vertical-stepper-items-circle {
				display: block;
				width: 15px;
				height: 15px;
				border-radius: 50px;
				// background-color: #0000001f;
			}

			.vertical-stepper-items-line {
				display: block;
				flex-grow: 1;
				border-left: 1px dashed #0000001f;
				border-right: 1px dashed #0000001f;
			}
		}

		.vertical-stepper-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 90%;
			padding-bottom: 20px;

			time {
				font-size: 10px;
			}

			p {
				font-size: 10px;
				// background-color: #00000061;
				margin-top: 4px;
				padding: 4px 8px;
				border-radius: 5px;
				color: #ffffff;
				font-weight: 500;
			}
		}
	}

	.vertical-stepper-item.completed {
		.vertical-stepper-item-styles {
			.vertical-stepper-items-circle {
				background-color: #4caf50;
			}
		}
		.vertical-stepper-content {
			p {
				background-color: #4caf50;
			}
		}
	}

	.vertical-stepper-item.in_progress {
		.vertical-stepper-item-styles {
			.vertical-stepper-items-circle {
				background-color: #ff9800;
			}
		}
		.vertical-stepper-content {
			p {
				background-color: #ff9800;
			}
		}
	}

	.vertical-stepper-item:last-child {
		.vertical-stepper-items-line:last-child {
			border: none;
		}
	}
}

.leaflet-control-container .leaflet-routing-container-hide {
	display: none;
}

.error-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000022;
	font-size: 24px;
	font-weight: bold;
}

.not_started .stepper-image-wrapper {
	filter: grayscale(100%);
}
