.table_scan {
	li {
		border: 1px solid rgba(97, 85, 85, 0.174);
		display: flex;
		align-items: center;

		div {
			width: 50%;
			padding: 5px 15px;
		}
	}

	ul {
		max-height: 150px;
		display: block;
		width: 100%;
		overflow: scroll;
	}

	.table_header {
		display: flex;
		border: 1px solid rgba(97, 85, 85, 0.174);

		p {
			width: 50%;
			padding: 5px 15px;
		}
	}
}
